<template>
    <div>
        <div class="page-filter-box">
            <el-row :gutter="20">
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="商家名称/手机" v-model="params.business" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-input placeholder="客户名称/手机" v-model="params.customer" size="small"></el-input>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4">
                    <el-select v-model="params.sort" size="small">
                        <el-option :value="1" label="按商家名称排序"></el-option>
                        <el-option :value="2" label="按客户名称排序"></el-option>
                    </el-select>
                </el-col>
                <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="4" class="page-filter-btns">
                    <el-button
                        type="primary"
                        icon="el-icon-search"
                        @click="search"
                        size="small"
                        >查询</el-button
                    >
                    <el-button
                        type="default"
                        icon="el-icon-refresh"
                        @click="reset"
                        size="small"
                        >重置</el-button
                    >
                </el-col>
            </el-row>
        </div>
        <div class="page-btns-box">
            <el-button type="primary" plain icon="el-icon-plus" @click="add" size="small">新增客户</el-button>
        </div>
        <div class="page-list-box">
            <el-table
                :data="dataList"
                border
                style="width: 100%"
                v-loading="listLoading"
                size="mini"
                stripe
            >
                <el-table-column prop="name" label="客户名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="familyName" label="家属姓名"></el-table-column>
                <el-table-column prop="familyRel" label="家属关系"></el-table-column>
                <el-table-column prop="telephone" label="联系电话"></el-table-column>
                <el-table-column prop="province" label="所在地区" show-overflow-tooltip min-width="150">
                    <template slot-scope="scope">{{scope.row.province}}/{{scope.row.city}}/{{scope.row.county}}</template>
                </el-table-column>
                <el-table-column prop="business" label="商家名称" show-overflow-tooltip></el-table-column>
                <el-table-column prop="status" label="状态"></el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="edit(scope.row.id)"
                            >编辑</el-button
                        >
                        <el-button
                            type="text"
                            size="small"
                            @click="fugao(scope.row.id)"
                            >讣告</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-pagination-box clearfix">
            <el-pagination
                v-if="data"
                background
                class="fr"
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pn"
                :page-sizes="[15,30,50]"
                :page-size.sync="params.ps"
                layout="total, sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>
    </div>
</template>
<script>

const defaultParams = {
    pn:1,
    business: "",
    customer: "",
    sort: 1,
    ps:15,
}
export default {
    data() {
        return {
            data: null,
            dataList: null,
            params: {
                ...defaultParams,
                pn: parseInt(this.$route.query.pn || 1),
                business: this.$route.query.business || "",
                customer: this.$route.query.customer || "",
                sort: parseInt(this.$route.query.sort || 1),
                ps: parseInt(this.$route.query.ps || 15),
            },
            listLoading: true,
        };
    },
    mounted() {
        this.getDataList();
    },
    methods: {
        getDataList() {
            this.listLoading = true;
            this.$request({
                url: "/api/ht/customer/list",
                params: this.params,
            })
                .then((res) => {
                    const { state, errmsg, result } = res.data;
                    if (state && result) {
                        this.data = result;
                        this.dataList = result.list || [];
                    } else {
                        this.$message.error(errmsg || "请求失败");
                    }
                })
                .catch(() => {
                    this.$message.error("请求错误");
                })
                .finally(() => {
                    this.listLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pn = 1;
            this.params.ps = e;
            this.getDataList();
        },
        //搜索
        search() {
            this.params.pn = 1;
            this.getDataList();
        },
        //重置搜索
        reset() {
            this.params = {
                ...defaultParams,
                ps: this.params.ps,
            };
            this.getDataList();
        },
        //编辑
        edit(id) {
            this.$router.push({
                path: `/customer/edit/${id}`,
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        {
                            ...this.params,
                        }
                    ),
                },
            });
        },
        add(){
            this.$router.push({
                path: `/customer/add`
            });
        },
        //讣告
        fugao(id){
            this.$router.push({
                path: `/fugao/edit`,
                query: {
                    redirect: this.$utils.objArgs(
                        this.$router.currentRoute.path,
                        {
                            ...this.params,
                        }
                    ),
                    customerId: id
                },
            });
        }
    },
};
</script>